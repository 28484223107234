/* Footer */

footer
{
	background: #111 repeat;
	padding-top: 20px;
	padding-bottom: 0px;
	color: #aaa;
	font-size: 12px;
	box-shadow: inset 0px 0px 10px #000;
	border-top: solid #333 5px;
}

body[class^="topic-"] footer
{
	margin-top: 20px;
}

footer a, footer a:visited{
	color: #ddd;
}

footer a:hover{
	color: #ccc;
	text-decoration: underline;
}

footer hr{
	height: 0px;
	padding: 0px;
	margin: 5px 0px;
	border-top: 1px solid #111;
	border-bottom: 1px solid #222;
}

footer h5{
	color: #fff;
}

footer h6{
	color: #fff;
}

footer ul{
	margin-left: 0px;
   padding-left:0px;
}

footer ul li{
	list-style-type: none;
	margin-bottom: 5px;
}

footer .widget{
	margin-bottom: 10px;
}

footer .copy{
	margin-bottom: 5px;
	font-size: 11px;
}

/* Social */

.social{
font-size:16px;
margin: 5px 0px;
}

.social i{
display:inline-block;
box-shadow:inset 0px 0px 1px #333;
height:25px;
width:25px;
line-height:25px;
margin:0px 5px 0px 0px;
border-radius:3px;
color:#fff;
-webkit-transition:background 1s ease;
-moz-transition:background 1s ease;
-o-transition:background 1s ease;
transition:background 1s ease;
text-align:center;
}

.social i.facebook{
background:#3c5b9b;
}

.social i.twitter{
background:#2daae1;
}

.social i.google-plus{
background:#f63e28;
}

.social i.pinterest{
background:#cb2027;
}

.social i.linkedin{
background:#0173b2;
}

.social i:hover{
background:#000;
-webkit-transition:background 1s ease;
-moz-transition:background 1s ease;
-o-transition:background 1s ease;
transition:background 1s ease;
}

.social a,.social a:visited,.social a:hover{
color:#fff;
text-decoration:none;
}
