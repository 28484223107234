/* General */

body {padding-top:71px}
.navbar {margin-bottom:0}
.tree {margin-top: 0px}
.tree dl {padding-left: 45px}
.tree dt {margin-bottom: 5px}
abbr[title="Required"] {color: #f00}
body .jumbotron {position: relative; display: block; height: 120px; width: 100%; background: no-repeat center; background-size: cover}
body .jumbotron.masthead {background-image: url('./main.jpg')}
.form-control:-moz-placeholder{color:#999}.form-control::-moz-placeholder{color:#999}.form-control:-ms-input-placeholder{color:#999}.form-control::-webkit-input-placeholder{color:#999}

/* Belt */

.carousel-inner {background: #fff}

.carousel.slide img
{
	width: 100%;
	height: auto;
	max-height: 300px;
}

.belt-strip
{
	display: table;
	width: 100%;
}

.belt-strip > div
{
	display: table-cell;
	vertical-align: middle;
	padding: 0px 15px;
}

/* Cycle */

.cycle-slideshow img
{
	display: block;
	width: 100%;
	height: auto;
}

/* Specific */

.colour-372 {background-color: #d8ed96}
.colour-486 {background-color: #ed9e84}
.colour-458 {background-color: #ddcc6b}
.colour-556 {background-color: #96aa99}
.colour-673 {background-color: #e08cb2}
.colour-714 {background-color: #f9ba82}
.colour-125 {background-color: #D5B146}
.colour-372 {background-color: #d8ed96}
.colour-551 {background-color: #a3c1c9}

table.results tbody td {
	white-space: nowrap;
}

.results.table-condensed th,
.results.table-condensed td
{
	font-size: 9px;
}

.x-hide-small {display: none}

.topic-front-page > .jumbotron {height: 300px;}

@media (min-width: 1296px)
{
	.x-hide-small {display: block}

	.x-row img
	{
		display: block;
		width: 100%;
		height: auto;
		border-right: 1px solid #fff;
		border-bottom: 1px solid #fff;
	}

	.x-row.x-hide-small,
	.x-row
	{
		display: table-row;
	}

	.x-row > *
	{
		display: table-cell;
		vertical-align: top;
	}

	.x-row > .col-30
	{
		width: 30%;
	}

	.x-row > .col-70
	{
		width: 70%;
	}
}

col[name="column-surname"] {min-width: 120px}
col[name="column-last-fights"] {min-width: 30px}
col[name="column-record"] {min-width: 100px}

.table-responsive{overflow-x:auto;min-height:.01%}@media screen and (max-width:767px){.table-responsive{width:100%;margin-bottom:15;overflow-y:hidden;-ms-overflow-style:-ms-autohiding-scrollbar;border:1px solid #c0c0c0}.table-responsive>.table{margin-bottom:0}.table-responsive>.table>thead>tr>th,.table-responsive>.table>tbody>tr>th,.table-responsive>.table>tfoot>tr>th,.table-responsive>.table>thead>tr>td,.table-responsive>.table>tbody>tr>td,.table-responsive>.table>tfoot>tr>td{white-space:nowrap}.table-responsive>.table-bordered{border:0}.table-responsive>.table-bordered>thead>tr>th:first-child,.table-responsive>.table-bordered>tbody>tr>th:first-child,.table-responsive>.table-bordered>tfoot>tr>th:first-child,.table-responsive>.table-bordered>thead>tr>td:first-child,.table-responsive>.table-bordered>tbody>tr>td:first-child,.table-responsive>.table-bordered>tfoot>tr>td:first-child{border-left:0}.table-responsive>.table-bordered>thead>tr>th:last-child,.table-responsive>.table-bordered>tbody>tr>th:last-child,.table-responsive>.table-bordered>tfoot>tr>th:last-child,.table-responsive>.table-bordered>thead>tr>td:last-child,.table-responsive>.table-bordered>tbody>tr>td:last-child,.table-responsive>.table-bordered>tfoot>tr>td:last-child{border-right:0}.table-responsive>.table-bordered>tbody>tr:last-child>th,.table-responsive>.table-bordered>tfoot>tr:last-child>th,.table-responsive>.table-bordered>tbody>tr:last-child>td,.table-responsive>.table-bordered>tfoot>tr:last-child>td{border-bottom:0}}

