	body { font-family: sans-serif; }
	.detail { display: flex; justify-content: center; align-items: flex-end; flex-direction: column; color: #999; margin: 20px 0; }
	.ranking-grid { display: grid; gap: 10px; grid-template-columns: 1fr 2fr 1fr; }
	.name { display: flex; justify-content: flex-end; align-items: center; font-size: 16pt; text-align: right; }
	.group { display: grid; gap: 10px; grid-template-columns: 1fr 40px 100px; }
	.country { display: flex; justify-content: flex-start; align-items: center; color: silver; text-transform: uppercase; }
	.country img { width: 40px; height: 30px; }
	.value { display: flex; justify-content: flex-start; align-items: center; font-size: 18pt; }
	.country-name { display: flex; justify-content: flex-end; }
	.country-name span { display: flex; justify-content: center; align-items: center; background: #999; color: white; padding: 8px; border-radius: 14px; }
	@media screen and (max-width: 500px) {
		.detail { align-items: center; }
		.ranking-grid { display: block; }
		.weight { display: flex; justify-content: flex-start; margin-top: 20px; color: #ccc; font-size: 8pt; }
		.country-name { display: none; }
	}
