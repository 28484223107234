.gloves {
	display: flex;
	justify-content: center;
	justify-content: flex-end;
}

.glove {
	margin: 4px 10px 4px 0px;
}

.article {
	min-height: 60px;
	
	&[data-category="true"] {
		margin-left: 20px;
		min-height: unset;
		margin-top: 8px;
	}
}

.ranking-grid {
	margin-bottom: 50px !important;
}

a.dropdown-toggle {
	cursor: pointer;
}

.results-header {
	display: grid;
	grid-template-columns: 500px 1fr;
	margin-top: -30px;

	.article {
	}
}

